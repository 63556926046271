









import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import { namespace } from 'vuex-class';
import { InvoiceStoreActions, InvoiceStoreGetters } from '@/store/invoice.store';
import Invoice from '@/models/Invoice.model';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const InvoiceStore = namespace('invoices');

@Component({
  components: {
    InvoiceListComponent: () => import(
      /* webpackChunkName: "InvoiceListComponent" */
      '@/components/InvoiceList.component.vue'
    )
  }
})
export default class InvoicesView extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public storeId!: string;
  
  @InvoiceStore.Action(InvoiceStoreActions.GET_BY_STORE)
  private getInvoicesByStoreAction!: (storeId: string) => Promise<Invoice>;

  @InvoiceStore.Getter(InvoiceStoreGetters.INVOICES_BY_STORE)
  private invoices!: Invoice[];

  private isLoading: boolean = false;

  async created() {
    try {
      this.isLoading = true;
      await this.getInvoicesByStoreAction(this.storeId);
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
