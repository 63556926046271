import ApiError from '@/api/misc/ApiError';
import GeneralApiError from '@/api/misc/GeneralApiError';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AxiosErrorHandlerMixin extends Vue {
  public handleAxiosError(err: ApiError, customHandler: (() => any) | undefined = undefined) {
    if (!(err instanceof GeneralApiError)) { // Error was not handled by interceptor
      if (customHandler) {
        customHandler();
      } else {
        console.log(err);
        Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    }
  }
}